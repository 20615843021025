import React from "react";
import { seoUrl } from "../lib/helpers";

import SectionHeader from "./product-section-header";
import IntegrationSection from "./product-integration-section";

import * as styles from "../styles/components/product-integrations.module.css";

const Integrations = (props) => {

  const {
    title,
    subtitle,
    _rawDescription,
    integrations
  } = props;

  return (
    <div className={styles.root} id={subtitle ? seoUrl(subtitle) : undefined}>
      {title && (<SectionHeader title={title} subtitle={subtitle} description={_rawDescription} />)}
      {integrations && integrations.length > 0 && (<IntegrationSection items={integrations} />)}
    </div>
  );
}

export default Integrations;
