import React from "react";
import { seoUrl } from "../lib/helpers";

import SectionHeader from "./product-section-header";
import FeaturedBlocks from "./fc-featured-blocks";
import MultiColumnContent from "./fc-multi-column-content";

import * as styles from "../styles/components/product-features.module.css";

const Features = (props) => {

  const {
    title,
    subtitle,
    _rawDescription,
    featuredBlocks,
    multiColumnContent,
    multiColumnContent2
  } = props;

  return (
    <div className={styles.root} id={subtitle ? seoUrl(subtitle) : undefined}>
      {title && (<SectionHeader title={title} subtitle={subtitle} description={_rawDescription} />)}
      {featuredBlocks && featuredBlocks.length > 0 && (<FeaturedBlocks items={featuredBlocks} />)}
      {multiColumnContent && (<MultiColumnContent {...multiColumnContent} />)}
      {multiColumnContent2 && (<MultiColumnContent {...multiColumnContent2} />)}
    </div>
  );
}

export default Features;
