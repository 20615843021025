import React, { useEffect } from "react";
import { graphql, navigate } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';

import PageHeader from "../components/product-page-header";
import Overview from "../components/product-overview";
import Features from "../components/product-features";
import HowItWorks from "../components/product-how-it-works";
import Integrations from "../components/product-integrations";
import Technology from "../components/product-technology";
import InAction from "../components/product-in-action";
import Partners from "../components/fc-partners";
import CallToAction from "../components/call-to-action";
import FeaturesOverView from "../components/product/product-features-overview";
import FeatureVerticalTabs from "../components/product/features-vertical-tabs";
import DeploymentOptions from "../components/product/deployment-options";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query ProductPageQuery {
    product: sanityProduct {
      pageHeader {
        title
        bg {
          asset {
            _id
          }
        }
      }
      themePurple
      overview {
        title
        subtitle
        _rawDescription
        featuredBlocks {
          _key
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
        multiColumnContent {
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
      }
      features {
        title
        subtitle
        _rawDescription
        featuredBlocks {
          _key
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
        multiColumnContent {
          title
          content {
            _key
            icon {
              asset {
                url
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
        multiColumnContent2 {
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
        }
      }
      howItWorks {
        title
        subtitle
        _rawContent
        image {
          ...SanityImage
          alt
        }
      }
      integrations {
        title
        subtitle
        _rawDescription
        integrations {
          _key
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
        }
      }
      technology {
        title
        subtitle
        _rawDescription
        partners {
          _key
          partner {
            name
            logo {
              asset {
                extension
                url
                _id
              }
              alt
            }
            slug {
              current
            }
            link
          }
        }
        featuredBlocks {
          _key
          title
          _rawContent
          link {
            blank
            text
            url
          }
          image {
            ...SanityImage
            alt
          }
          switchOrder
        }
        multiColumnContent {
          title
          content {
            _key
            icon {
              asset {
                _id
              }
            }
            title
            _rawContent
            link {
              blank
              text
              url
            }
          }
          themeColorBg
          centerAligned
          alternativeLayout
        }
      }
      inAction {
        title
        subtitle
        _rawDescription
        showcase {
          _key
          showcase {
            title
            categories {
              _key
              showcaseCategory {
                title
                slug {
                  current
                }
              }
            }
            slug {
              current
            }
            thumbnail {
              ...SanityImage
              alt
            }
          }
        }
        button {
          blank
          secondaryButton
          text
          url
          document {
            asset {
              originalFilename
              url
            }
          }
        }
      }
      partners {
        title
        partners {
          _key
          partner {
            name
            slug {
              current
            }
            logo {
              alt
              asset {
                extension
                url
                _id
              }
            }
          }
        }
      }
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const ProductPage = (props) => {
  const { data, errors } = props;
  useEffect(() => {
    navigate("/"); // Redirect to homepage
  }, []);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const product = (data || {}).product;

  const title = product.seo?.title || product.pageHeader?.title;
  const description = product.seo?.description;
  const image = product.seo?.thumbnail;
  const autoSiteTitle = !product.seo?.title;


  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={product.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {product.pageHeader && (<PageHeader {...product.pageHeader} overview={product.overview?.subtitle} features={product.features?.subtitle} howItWorks={product.howItWorks?.subtitle} integrations={product.integrations?.subtitle} technology={product.technology?.subtitle} inAction={product.inAction?.subtitle} />)}
      {product.overview && (<Overview {...product.overview} />)}
      {product.howItWorks && (<HowItWorks {...product.howItWorks} />)}
      {product.features && (<Features {...product.features} />)}
      {product.integrations && (<Integrations {...product.integrations} />)}
      {product.technology && (<Technology {...product.technology} />)}
      {product.inAction && (<InAction {...product.inAction} />)}
      {product.partners && (<Partners {...product.partners} />)}
      {product.callToAction && (<CallToAction {...product.callToAction} />)}
    </Layout>
  );
};

export default ProductPage;
