import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import PortableText from "./portableText";
import Icon from "./icon";

import * as styles from "../styles/components/product-integration-section.module.css";

function IntegrationSection({ items }) {
  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          {items.map(({ _key, title, _rawContent, link, image }) => {
            return (
              <div key={_key} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                <div className={styles.image}>
                  {image && image.asset && (
                    <>
                      {(link && link.url) ? (
                        <>
                          {link.blank ? (
                            <a href={link.url} target="_blank" rel="noreferrer" className={styles.link}>
                              <img
                                src={imageUrlFor(buildImageObj(image))
                                  .auto("format")
                                  .url()}
                                alt={image.alt}
                              />
                            </a>
                          ) : (
                            <Link to={link.url} className={styles.link}>
                              <img
                                src={imageUrlFor(buildImageObj(image))
                                  .auto("format")
                                  .url()}
                                alt={image.alt}
                              />
                            </Link>
                          )}
                        </>
                      ) : (
                        <img
                          src={imageUrlFor(buildImageObj(image))
                            .auto("format")
                            .url()}
                          alt={image.alt}
                        />
                      )}
                    </>
                  )}
                </div>
                <div className={styles.text}>
                  {title && (
                    <h3 className={cn("h5", styles.title)}>
                      {(link && link.url) ? (
                        <>
                          {link.blank ? (
                            <a href={link.url} target="_blank" rel="noreferrer" className={styles.link}>
                              {title}
                            </a>
                          ) : (
                            <Link to={link.url} className={styles.link}>
                              {title}
                            </Link>
                          )}
                        </>
                      ) : (
                        {title}
                      )}
                    </h3>
                  )}
                  {_rawContent && <PortableText blocks={_rawContent} />}
                  {link && link.url && (
                    <>
                      {link.blank ? (
                        <a href={link.url} className={cn("link", styles.viewLink)} target="_blank" rel="noreferrer">{link.text}<Icon symbol="arrow-right" /></a>
                      ) : (
                        <Link to={link.url} className={cn("link", styles.viewLink)}>{link.text}<Icon symbol="arrow-right" /></Link>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default IntegrationSection;
